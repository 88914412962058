*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

button,
select {
  cursor: pointer;
  font-family: inherit;
}

button {
  background-color: transparent;
  border: none;
}

[disabled] {
  pointer-events: none;
}

a {
  text-decoration: none;
}

input {
  appearance: none;
}

hr {
  border: 0.1rem solid;
}
