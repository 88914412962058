@import 'variables';
@import 'vendor/cookiebot';

body {
  font-family: $font-family-primary;
  font-size: $body-font-size;
  color: $color-neutral-01;
}

*:focus-visible {
  outline: 2px solid #2c73f5;
  outline-offset: 1px;
}
