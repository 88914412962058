@import '~theme/variables';

.container {
  padding: $space-xxs;
  padding-left: $space-s;
  border-radius: 999999px;
  display: inline-flex;
  position: fixed;
  bottom: $space-s;
  left: 50%;
  transform: translateX(-50%);
  background: #1c6cff;
  color: white;
  gap: $space-xs;
  font-size: 1.4rem;
  font-weight: 500;
  align-items: center;
  z-index: 999;
  text-wrap: nowrap;
}

.button {
  background: none;
  border: none;
  color: white;
  font-size: 1.4rem;
  text-decoration: underline;
  font-weight: 500;
  padding: $space-xs $space-s;
  border-radius: 999999px;

  &:hover {
    // text-decoration: none;
    background-color: rgb(255, 255, 255, 0.2);
  }
}
