/* stylelint-disable */
#CybotCookiebotDialogBodyContentTitle,
#CybotCookiebotDialogBodyContentText {
  font-family: $font-family-primary !important;
}

#CybotCookiebotDialogBodyContentTitle {
  font-weight: 600 !important;
}

#CybotCookiebotDialogBodyContentText {
  font-size: 1.2rem !important;
  line-height: $body-line-height !important;
  color: $color-neutral-02 !important;
}

#CybotCookiebotDialogBodyButtonAccept {
  font-family: $font-family-primary !important;
  border-radius: $border-radius-01 !important;
  padding: 1rem 2.4rem !important;
  font-size: 1.6rem !important;
}

#CybotCookiebotDialog a,
#CybotCookiebotDialog div,
#CybotCookiebotDialogBodyContentControls,
#CybotCookiebotDialogBodyContentTitle {
  font-family: $font-family-primary !important;
}
